import { service } from '@/ajax/request';
import { ServiceProviderPage, ServiceProviderInfo } from "@/types/api/serviceProvider";

export const userList = (params: any) => (
    service({
      url: '/user/userMember/getUserList',
      method: 'GET',
      params,
      type: 'form'
    })
  )

export const getStoreServiceList = (params: ServiceProviderPage) => {
    return service({
        url: `/store/storeService/list`,
        method: 'POST',
        params,
    })
}

export const addStoreService = (params: ServiceProviderInfo) => {
    return service({
        url: `/store/storeService/saveStoreService`,
        method: 'post',
        params,
    })
}

export const updateStoreService = (params: ServiceProviderInfo) => {
    return service({
        url: `/store/storeService/updateStoreService`,
        method: 'post',
        params,
    })
}

export const updateStoreServiceStatus = (params: { enableState: any, storeServiceId: any }) => {
    return service({
        url: `/store/storeService/enableState`,
        method: 'post',
        params,
        type: 'form'
    })
}
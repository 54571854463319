
import { Watch, Prop, Mixins, Component, Ref, InjectReactive } from "vue-property-decorator";

import ETable from "@/components/ETable.vue";
import TablePagination from "@/mixins/tablePagination";

import { detectionSummaryLog } from "@/api/userDetection";

@Component({
    components: { ETable }
})
export default class UserBuyRecord extends Mixins(TablePagination) {
    @Prop() memberInfo;

    loading = false;
    tableData = [];
    itemTotal = 0;

    diagnosesTypeOptions = [
        {label: "舌诊",value: "10001"}
    ];

    columns = [
        { label: "诊断类型", prop: "diagnosesTypeDesc" },
        { label: "添加次数", prop: "buyNum" },
        { label: "添加人", prop: "modifiedBy" },
        { label: "添加日期", prop: "modifiedTime" },
        { label: "备注", prop: "remark" },
    ]

    mounted() {
        this.handleSearch();
    }

    //重置分页参数
    resetPages() {
        this.currentPage = 1;
    }

    // 搜索
    handleSearch() {
        this.resetPages();
        this.getData();
    }

    getData() {
        const params = {
            memberId: this.memberInfo.memberId,
            pageNum: this.currentPage,
            pageSize: this.pageSize,
        }
        this.loading = true;
        detectionSummaryLog(params).then((res) => {
            const { total, list } = res.data;
            list.forEach((record) => {
               record.diagnosesTypeDesc = this.diagnosesTypeOptions.find((el) => el.value == record.diagnosesType)?.label;
            })
            this.tableData = list;
            this.itemTotal = total;
        }).finally(() => {
            this.loading = false;
        })
    }

}



import { Watch, Mixins, Component, Ref, InjectReactive, Emit } from "vue-property-decorator";

import TablePagination from "@/mixins/tablePagination";
import Resize from "@/mixins/resize";

import ETable from "@/components/ETable.vue";
import UserBuyRecord from "./buyRecord.vue";

import { ElForm } from "element-ui/types/form";

import { userList } from "@/api/serviceProvider"

// 工具
import { debounce, cloneDeep } from "lodash";

import { detectionSummary, detectionSummaryList, detectionSummaryDetail } from "@/api/userDetection";

@Component({
  components: {
      ETable,
      UserBuyRecord
  }
})
export default class UserDetectionTimes extends Mixins(Resize, TablePagination) {
    @Ref("addFormRef") AddFormRef!: ElForm;
    @InjectReactive() activeName!: string;
    
    queryForm = {
        phone: "",
        nickname: "",
    }

    loading = false;
    itemTotal = 0;
    tableData = [];
    memberInfo = {};
    memberRecordVisible = false;

    columns = [
        { label: "用户ID", prop: "memberId" },
        { label: "用户头像", prop: "icon" },
        { label: "用户名称", prop: "nickname" },
        { label: "用户手机号", prop: "phone" },
        { label: "诊断类型", prop: "diagnosesTypeDesc" },
        { label: "总剩余次数", prop: "leaveNum" },
        { label: "总检测次数", prop: "diagnosesNum" },
        // { label: "操作人", prop: "modifiedBy" },
        // { label: "最后操作时间", prop: "modifiedTime" },
        { label: "操作", prop: "actions" },
    ]

    diagnosesTypeOptions = [
        {label: "舌诊",value: "10001"}
    ];

    dialogVisible = false;
    userSelectLoading = false;
    allUserList = [];
    selectUserInfo: any = {};
    form = {
        diagnosesType: "10001",
        memberId: "",
        nickname: "",
        times: 0,
        buyNum: 0,
        remark:"",
    };

    formRules = {
        diagnosesType: [{ required: true, trigger: "blur" }],
        memberId: [{ required: true, message: "请选择用户手机号", trigger: "blur" }],
        buyNum: [{ required: true, message: "请输入添加次数", validator: this.buyNumValidator, trigger: "blur" }],
    }

    saveLoading = false;

    buyNumValidator(rule, value, callback) {
        if (value > 0) return callback();
        callback(new Error())
    }

    mounted() {
        this.windowResize(480);
    }

    //重置分页参数
    resetPages() {
        this.currentPage = 1;
    }

    // 搜索
    handleSearch() {
        this.resetPages();
        this.getData();
        this.syncCount();
    }

    @Emit("syncCount")
    syncCount(){
        return "syncCount";
    }

    @Watch("activeName", { immediate: true })
    getData() {
        if (this.activeName !== "first") return;
        const params = {
            ...this.queryForm,
            pageNum: this.currentPage,
            pageSize: this.pageSize,
        }
        this.loading = true;
        detectionSummaryList(params).then((res) => {
            const { total, list } = res.data;
            list.forEach((record) => {
                record.diagnosesTypeDesc = this.diagnosesTypeOptions.find((el) => el.value == record.diagnosesType)?.label;
            })
            this.tableData = list;
            this.itemTotal = total;
        }).finally(() => {
            this.loading = false;
        })
    }

    searchData() {

    }

    addTimes() {
        this.dialogVisible = true;
    }

    memberIdChange($event) {
        const userInfo = this.allUserList.find((el) => el.id == $event)
        Object.assign(this.selectUserInfo, userInfo);
        detectionSummaryDetail(userInfo.id).then((res) => {
            this.$set(this.selectUserInfo, 'leaveNum', res.data.leaveNum)
        });
    }

    getUserRemote = debounce(function (str) {
        this.userSelectLoading = true;
        userList({phone: str,pageNum: 1,pageSize: 500})
        .then((res) => {
            this.userSelectLoading = false;
            this.allUserList = res.data || [];
        })
        .catch((err) => {});
    })

    getUserList(str) {
        if (typeof str == "object") {
            str = "";
        }
        this.getUserRemote(str);
    }

    cancel() {
        this.selectUserInfo = {};
        this.AddFormRef.resetFields();
        this.dialogVisible = false;
        this.saveLoading = false;
    }

    save() {
        const { buyNum, diagnosesType, memberId, remark } = this.form;
        const params = { buyNum, diagnosesType, memberId, remark};
        this.AddFormRef.validate().then(() => {
            this.saveLoading = true;
            detectionSummary(params).then((res) => {
                this.$message.success("操作成功");
                this.cancel();
                this.getData();
                this.syncCount();
            })
        })
    }

    viewMemberRecord(row) {
        this.memberInfo = row;
        this.memberRecordVisible = true;
    }
    
    closeMemberRecord() {
        this.memberRecordVisible = false;
    }
}

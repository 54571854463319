
  import { Watch, Mixins, Component, Vue, Ref, Provide, ProvideReactive } from "vue-property-decorator";

  import TablePagination from "@/mixins/tablePagination";
  import Resize from "@/mixins/resize";

  import UserDetectionTimes from "./components/userDetectionTimes.vue";
  import UserBuyRecord from "./components/userBuyRecord.vue";

  import { detectionSummaryCount } from "@/api/userDetection";
  
  @Component({
    inject: [],
    components: {
        UserDetectionTimes,
        UserBuyRecord
    }
  })
  export default class UserDetectionRecord extends Mixins(Resize, TablePagination) {
    @ProvideReactive()
    activeName = "first";

    detectionTotal = [
        { quantity: 0, label1: "舌诊剩余可调用量", label2: "" },
        { quantity: 0, label1: "舌诊总分配次数", label2: "（含小程序/门店）" },
        { quantity: 0, label1: "舌诊总检测次数", label2: "（含小程序/门店）" },
        { quantity: 0, label1: "舌诊总购买次数", label2: "（小程序）" },
    ]

    mounted() {
        this.getData();
    }

    getData() {
        detectionSummaryCount().then((res) => {
            const { allBuyNum, allUserBuy, allDetectionTimes, allLeaveNum } = res.data;
            this.detectionTotal[0].quantity = allLeaveNum;
            this.detectionTotal[1].quantity = allBuyNum;
            this.detectionTotal[2].quantity = allDetectionTimes;
            this.detectionTotal[3].quantity = allUserBuy;
        })
    }
  }
